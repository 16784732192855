export const GET_TAGS = "GET_TAGS";
export const SET_LOADING = "SET_LOADING";
export const SET_ALL_FILTERS = "SET_ALL_FILTERS";
export const SET_RESULTS_PAGE_NUMBER = "GET_RESULTS_PAGE_NUMBERS";
export const GET_SEARCH_RESULTS_ALL = "GET_SEARCH_RESULTS_ALL";
export const SET_LIFESTYLE_FILTER = "SET_LIFESTYLE_FILTER";
export const SET_CONSCIOUS_FILTER = "SET_CONSCIOUS_FILTER";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const CLEAR_RESTAURANT_LIST = "CLEAR_RESTAURANT_LIST";
export const SET_RESTAURANT_SEARCH_FILTER = "SET_RESTAURANT_SEARCH_FILTER";
export const GET_SEARCH_RESULTS_MAP = "GET_SEARCH_RESULTS_MAP";
// export const GET_ARTICLE_MAP = "GET_ARTICLE_MAP";
// export const GET_ARTICLE = "GET_ARTICLE";

// export const SET_DINING_FILTER = "SET_DINING_FILTER";
// export const SET_CUISINE_FILTER = "SET_CUISINE_FILTER";
// export const SET_MEAL_FILTER = "SET_MEAL_FILTER";
// export const SET_PRICE_FILTER = "SET_PRICE_FILTER";
// export const SET_VIBE_FILTER = "SET_VIBE_FILTER";
// export const SET_NEIGHBORHOOD_FILTER = "SET_NEIGHBORHOOD_FILTER";
