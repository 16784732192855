import React, { useState } from "react";
import Logo from "./Logo";
import FooterIcons from "../icons/FooterIcons";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import FooterStyles from "../../styles/FooterStyles";
import { emailSignup } from "../../utils/emailSignup";

const Footer = () => {
  const [confirmation, setConfirmation] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailSignup(e, setConfirmation);
  };

  return (
    <>
      {/* <p className="dropdown-learn-more">
          <Link to="/values" className="dropdown-learn-more-link">
            Learn more
          </Link>{" "}
          about our five-value system for evaluating restaurants and
          highlighting what they do well.
        </p> */}
      <FooterStyles>
        <div className="signup-and-footer">
          <div className="photo-and-signup">
            <StaticImage
              alt=""
              src="https://res.cloudinary.com/table-sage/image/upload/c_scale,f_auto,q_auto:good,w_1500/v1628773380/ts-homepage/2020-04-16_04.11.49_1_okppy0.jpg"
              placeholder="blurred"
              layout="constrained"
              className="photo"
            />
            <div className="signup">
              {confirmation ? (
                <>
                  <h6>
                    Success! Welcome to{" "}
                    <span className="no-wrap">Table Sage.</span>
                  </h6>
                  <p>You'll receive monthly updates to your inbox.</p>
                </>
              ) : (
                <>
                  <h6>Food for thought.</h6>
                  <form
                    autoComplete="off"
                    className="form"
                    id="suggest-form-footer"
                    onSubmit={sendEmail}
                  >
                    <input
                      name="email-footer"
                      id="email-footer"
                      type="email"
                      className="form__input"
                      placeholder="Email..."
                      required
                    />
                    <input
                      name="hair-color-footer"
                      id="hair-color-footer"
                      type="text"
                      className="form__input--hp "
                      autoComplete="off"
                      placeholder="hair color"
                    />
                    <input
                      type="submit"
                      value="Subscribe"
                      className="form__button"
                    />
                  </form>
                </>
              )}
            </div>
          </div>
          <div className="footer">
            <div className="section-1">
              <div className="follow">
                <h6>Follow</h6>
                <FooterIcons />
              </div>
              <div className="links">
                <div className="explore">
                  <h6>Explore</h6>
                  <ul>
                    <li>
                      <Link to="/locate">Recommended restaurants</Link>
                    </li>
                    <li>
                      <Link className="sub-link" to="/locate">
                        → Amsterdam
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="sub-link" to="/locate/london">
                        → London
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/values">Our rating process</Link>
                    </li>
                    <li>
                      <Link to="/theory-of-change">Our impact</Link>
                    </li>
                    <li>
                      <a href="https://noteworthy.table-sage.com/">
                        Industry news
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="connect">
                  <h6>Connect</h6>
                  <ul>
                    <li>
                      <Link to="/partnerships">Partner with us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/about">About us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="section-2">
              <div className="footer-copy">
                <h6>#ConsciousDining</h6>
                <p>Rethink what makes restaurants great.</p>
              </div>
              <div className="logo-links">
                <Link to="/">
                  <Logo color="white" />
                </Link>
                <p>&copy; 2024 Table Sage, All Rights Reserved</p>
                <Link to="/privacy-and-cookies">
                  <p>Privacy & Cookies</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </FooterStyles>
    </>
  );
};

export default Footer;
