import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --off-black: #231f20;
    /* --ts-green: #c8ceba; */
    --light-gray: #ebecee;
    /* --footer-gray: #f4f5f6; */
    --footer-gray: #f3eae3;
    --footer-med-gray: #BFBBBD;
    --medium-gray: #757575;
    --cornflower: #5b70c7;
    --ts-tan: #f3eae3;
    --neon-yellow: #F0FD9D;
    --ts-green: #F0FD9D;
  }

  *,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

body {
  box-sizing: border-box;
  width: 100%;

  &:focus {
    outline: none;
  }
}


  a {
    text-decoration: none;
    color: var(--off-black);
  }

  .team-timeline {
    content: "";
    background-color: var(--off-black);
    height: 200px;
    width: 100%;
  }

  .conscious-icon-bg {
  background-color: var(--ts-green);
  content: "";
  width: 37px;
  height: 37px;
  border-radius: 100px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;

  &--small {
    background-color: var(--ts-green);
    content: "";
    width: 34px;
    height: 34px;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
  }

  &--space {
    margin-right: 6px;
  }
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    padding: 0 5px;
    background-color: hsla(0,0%,100%,0);
    margin: 0;
}

.mapboxgl-ctrl-attrib a {
  color: var(--footer-med-gray);
  text-decoration: none;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    display: none;
  }

  .mapboxgl-popup-content {
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none;
    border-radius: 20px;
  }

  .mapboxgl-popup-tip {
    display: none;
  }

  // .mapboxgl-canvas-container {
  //   width: calc(90vw - 640px);
  // }

  .mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .popup {
    white-space: pre-wrap;
    display: inline-block;
    box-shadow: none !important;
    border-radius: 20px;


    &-container {
      padding: 10px 16px;
      border: none;
      box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.1);
      border-radius: 20px;

      h3 {
      font-family: var(--header-font-bold) !important;
      max-width: 200px;
      font-size: 14px !important;
      line-height: 20px !important;
      color: var(--off-black) !important;
      margin-bottom: 2px;
    }

    p {
      font-family: var(--body-font) !important;
      display: block;
      max-width: 200px;
      font-size: 12px !important;
      line-height: 16px !important;
      color: var(--off-black) !important;
    }
    }

   
  }

  .hidden-name,
  .hidden-info {
    display: none;
  }

  .popup-closed {
    visibility: hidden;
  }


  .value-rating {
    font-size: 16px;
    background-color: var(--neon-yellow);
    display: inline-block;
    line-height: 16px;
    margin-right: 4px;
    margin-bottom: 6px;
    padding: 3px 8px 4px;
    border-radius: 20px;
  }

.custom-toast {
  background-color: var(--neon-yellow);
  padding: 10px 50px;
  border-radius: 100px;
}

//hide checkbox for map/list view button on smaller screens
#map-list-button {
  display: none;
}

.pl-3 {
padding-left: 30px !important;
}

.prl-1 {
  padding: 0 10px !important;
}

.prl-2 {
  padding: 0 20px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.pt-4 {
  padding-top: 40px !important;
}

.pt-5 {
  padding-top: 50px !important;
}

.pt-6 {
  padding-top: 60px !important;
}

.pt-8 {
  padding-top: 80px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-2 {
  padding-bottom: 20px !important;
}

.pb-4 {
  padding-bottom: 40px !important;
}

.pb-5 {
  padding-bottom: 50px !important;
}

.pb-57 {
  padding-bottom: 57px !important;
}

.pb-8 {
  padding-bottom: 80px !important;
}

.mb-10 {
  margin-bottom: 100px !important;
}

.mb-8 {
  margin-bottom: 80px !important;
}

.mb-6 {
  margin-bottom: 60px !important;
}

.mb-5 {
  margin-bottom: 50px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-8 {
  margin-top: 80px !important;
}

.mt-7 {
  margin-top: 70px !important;
}

.mt-6 {
  margin-top: 60px !important;
}

.mt-5 {
  margin-top: 50px !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mr-3 {
  margin-right: 30px !important;
}

.mr-4 {
  margin-right: 40px !important;
}

.align-left {
  text-align: left !important;
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}

.ten-sixteen {
  display: flex;
  flex-direction: row;
  width: 1016px;
}

.thirteen-seventy {
  display: flex;
  flex-direction: row;
  width: 88.5vw;
}

.container-relative {
  position: relative;
}

.spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.invisible {
  display: none !important;
}

.hidden {
  visibility: hidden;
}

.block {
  display: block;
}

.transparent {
  opacity: 0;
}

.max-240 {
  max-width: 240px;
}

.center-text {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.loader-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 200px;
}

.no-wrap {
  white-space: nowrap;
}

.center-button {
  width: 100%;
  position: relative;
  text-align: center;
}

.top-msg {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 60px auto;
    max-width: 750px;
    padding: 0 12px;
}

.learn-more--search-page {
  padding: 80px 0 80px;
  max-width: 420px;
  margin: 0 auto;
}

.bottom-msg {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
    padding: 60px 0;
  }

@media only screen and (max-width: 1145px) and (pointer: coarse) {
  .learn-more--search-page {
  padding: 80px 20px 80px;
  max-width: 375px;
}
}

`;

export default GlobalStyles;
