import styled from "styled-components";

const FooterStyles = styled.footer`
  background: white;
  width: 100%;
  /* padding-top: 40px; */

  .sub-link {
    font-size: 16px !important;
  }

  .logo-container {
    margin-bottom: 10px;
  }

  .photo-and-signup {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .photo {
    width: 50%;
    height: 194px;
  }

  .signup {
    width: 50%;
    background-color: #c0c9bd;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h6 {
      font-family: var(--header-font);
      font-size: 25px;
      line-height: 34px;
    }
  }

  .form {
    position: relative;

    &__button {
      font-family: var(--body-font);
      position: relative;
      width: 100px;
      background: white;
      line-height: 44px;
      font-size: 17px;
      color: var(--off-black);
      outline: none;
      border: 1px solid var(--footer-med-gray);
      cursor: pointer;
      border-left: 0;
      /* padding-top: 1px; */
      border-radius: 0;
      -webkit-appearance: none;
    }

    &__input {
      font-family: var(--body-font);
      width: calc(100% - 100px);
      margin: 12px 0 0 0;
      border: 1px solid var(--footer-med-gray);
      border-radius: 0;
      cursor: pointer;
      padding: 0 15px;
      font-family: var(--body-font);
      color: var(--off-black);
      font-style: normal;
      line-height: 44px;
      /* padding-bottom: 1px; */
      font-size: 17px;
      border-radius: 0;
      -webkit-appearance: none;

      &::placeholder {
        color: var(--footer-med-gray);
      }

      &--hp {
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: -10;
      }
    }
  }

  .footer {
    background-color: var(--off-black);
    color: white;
    padding: 50px;
    display: flex;
    flex-direction: row-reverse;
    height: 520px;

    &__icons {
      padding-top: 2px;

      a {
        margin-right: 10px;
      }
    }

    h6 {
      font-family: var(--header-font);
      font-size: 25px;
      line-height: 30px;
      font-weight: normal !important;
      margin-bottom: 14px;
    }

    ul {
      li {
        list-style: none;

        a {
          color: white;
          font-size: 18px;
          line-height: 28px;
          /* padding-left: 12px; */
        }
      }
    }
  }

  .links {
    display: flex;
    flex-direction: row;
  }

  .follow {
    margin-bottom: 80px;
  }

  .explore {
    margin-right: 150px;
  }

  .section-1 {
    width: 50%;
    padding-left: 60px;
  }

  .section-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .logo-links {
    p {
      color: #6f6366;
      font-size: 14px;
      display: inline-block;
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 1075px) {
    //! adjust so that explore links don't wrap to second line

    .explore {
      margin-right: 100px;
    }
  }

  @media only screen and (max-width: 984px) {
    .photo-and-signup {
      flex-direction: column;
    }

    .photo {
      width: 100%;
    }

    .signup {
      width: 100%;
      height: 194px;
      align-items: center;
    }

    .form {
      position: relative;

      &__input {
        width: 360px;
      }
    }

    .footer {
      padding: 50px;
      display: block;
      height: auto;

      ul {
        li {
          a {
            /* padding-left: 12px; */
          }
        }
      }
    }

    .links {
      display: block;
    }

    .follow {
      margin-bottom: 45px;
    }

    .explore {
      margin-right: 0;
      margin-bottom: 45px;
    }

    .section-1 {
      width: 100%;
      padding-left: 0;
      margin-bottom: 90px;
    }

    .section-2 {
      width: 100%;
      display: block;
    }

    .footer-copy {
      margin-bottom: 75px;
    }
  }

  @media only screen and (max-width: 600px) {
    .footer {
      padding: 50px 40px;
    }

    .form {
      width: 100%;

      &__input {
        width: calc(100% - 100px);

        &--hp {
          position: absolute;
          left: 0;
        }
      }
    }

    .signup {
      padding: 0 40px;
    }
  }

  @media only screen and (max-width: 500px) {
    .footer {
      padding: 50px 20px;
    }

    .form {
      &__input {
        width: calc(100% - 100px);
      }
    }

    .signup {
      padding: 0 20px;
    }
  }
`;

export default FooterStyles;
