const React = require("react");
const Layout = require("./src/components/layout/Layout").default;
const wrapWithProvider = require("./wrap-with-provider").default;
require("mapbox-gl/dist/mapbox-gl.css");

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.wrapRootElement = wrapWithProvider;
