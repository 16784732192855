import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "./Logo";
import NavStyles from "../../styles/NavStyles";
import FooterIcons from "../icons/FooterIcons";
import { emailSignup } from "../../utils/emailSignup";

const Nav = ({ color }) => {
  const [confirmation, setConfirmation] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailSignup(e, setConfirmation);
  };

  const closeNav = () => {
    // close mobile menu on link click
    if (typeof window !== `undefined`) {
      document.querySelector("#nav-toggle").checked = false;
      document.querySelector("body").style.height = "initial";
      document.querySelector("body").style.overflow = "initial";
    }
  };

  const disableScroll = (e) => {
    //disable background scroll when menu is open
    if (typeof window !== `undefined` && e.currentTarget.checked) {
      document.querySelector("body").style.height = "100vh";
      document.querySelector("body").style.overflow = "hidden";
    }
    //enable background scroll when menu is closed
    if (typeof window !== `undefined` && !e.currentTarget.checked) {
      document.querySelector("body").style.height = "initial";
      document.querySelector("body").style.overflow = "initial";
    }
  };

  return (
    <NavStyles className="nav-bar">
      <Link to="/">
        <Logo color="white" />
      </Link>

      <input
        type="checkbox"
        className="nav-bar__checkbox"
        id="nav-toggle"
        onChange={disableScroll}
      />
      <label htmlFor="nav-toggle" className="nav-bar__open--mobile">
        <div className="nav-toggle-target">
          <div
            className={`nav-bar__hamburger--${color}-bar  nav-bar__hamburger--bar1`}
          ></div>
          <div
            className={`nav-bar__hamburger--${color}-bar nav-bar__hamburger--bar2`}
          ></div>
          <div
            className={`nav-bar__hamburger--${color}-bar  nav-bar__hamburger--bar3`}
          ></div>
        </div>
      </label>
      <div className="nav-bg" onClick={closeNav}></div>
      <div className="nav-bar__links--mobile">
        <div className="links">
          <div className="explore">
            <h6>Explore</h6>
            <ul>
              <li>
                <Link onClick={closeNav} to="/locate">
                  Recommended restaurants
                </Link>
              </li>
              <li>
                <Link className="sub-link" onClick={closeNav} to="/locate">
                  → Amsterdam
                </Link>
              </li>
              {/* <li>
                <Link
                  className="sub-link"
                  onClick={closeNav}
                  to="/locate/london"
                >
                  → London
                </Link>
              </li> */}
              <li>
                <Link onClick={closeNav} to="/values">
                  Our rating process
                </Link>
              </li>
              <li>
                <Link onClick={closeNav} to="/theory-of-change">
                  Our impact
                </Link>
              </li>
              <li>
                <a href="https://noteworthy.table-sage.com/">Industry news</a>
              </li>
            </ul>
          </div>
          <div className="connect">
            <h6>Connect</h6>
            <ul>
              <li>
                <Link onClick={closeNav} to="/partnerships">
                  Partner with us
                </Link>
              </li>
              <li>
                <Link onClick={closeNav} to="/contact">
                  Contact us
                </Link>
              </li>
              <li>
                <Link onClick={closeNav} to="/about">
                  About us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="follow">
          <h6>Follow</h6>
          <FooterIcons />
        </div>
        <div className="signup">
          {confirmation ? (
            <>
              <h6>
                Success! Welcome to <span className="no-wrap">Table Sage.</span>
              </h6>
              <p>You'll receive monthly updates to your inbox.</p>
            </>
          ) : (
            <>
              <h6>Food for thought.</h6>
              <form
                autoComplete="off"
                className="form"
                id="suggest-form-nav"
                onSubmit={sendEmail}
              >
                <input
                  name="email-nav"
                  id="email-nav"
                  type="email"
                  className="form__input"
                  placeholder="Email..."
                  required
                />
                <input
                  name="hair-color-nav"
                  id="hair-color-nav"
                  type="text"
                  className="form__input--hp "
                  autoComplete="off"
                  placeholder="hair color"
                />
                <input
                  type="submit"
                  value="Subscribe"
                  className="form__button"
                />
              </form>
            </>
          )}
        </div>
      </div>
    </NavStyles>
  );
};

export default Nav;
