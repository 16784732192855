import styled from "styled-components";

const NavStyles = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
  height: 70px;
  background: var(--off-black);
  color: white;

  .logo-container {
    height: 47px;
    margin-bottom: 2px;
  }

  h6 {
    font-family: var(--header-font);
    font-size: 25px;
    line-height: 34px;
    color: white;
    margin-bottom: 10px;
  }

  ul {
    li {
      list-style: none;

      a {
        color: white;
        font-size: 18px;
        line-height: 28px;
        /* padding-left: 12px; */
      }
    }
  }

  .sub-link {
    font-size: 16px;
  }

  .nav-toggle-target {
    content: "";
    display: inline-block;
    height: 30px;
    width: 34px;
    padding: 8px 4px;
  }

  .nav-toggle {
    display: inline-block;
  }

  .form {
    position: relative;

    &__button {
      font-family: var(--body-font);
      position: relative;
      width: 100px;
      background: var(--off-black);
      line-height: 44px;
      font-size: 17px;
      color: white;
      outline: none;
      border: 1px solid white;
      cursor: pointer;
      border-left: 0;
      padding-bottom: 1px;
      border-radius: 0;
      -webkit-appearance: none;
    }

    &__input {
      font-family: var(--body-font);
      background: var(--off-black);
      width: calc(100% - 100px);
      max-width: 300px;
      margin: 12px 0 0 0;
      border: 1px solid white;
      border-radius: 0;
      cursor: pointer;
      padding: 0 15px;
      font-family: var(--body-font);
      color: white;
      font-style: normal;
      line-height: 44px;
      padding-bottom: 1px;
      font-size: 17px;
      border-radius: 0;
      -webkit-appearance: none;
      margin-bottom: 80px !important;

      &::placeholder {
        color: white;
      }

      &--hp {
        position: absolute;
        top: 0;
        opacity: 0;
        z-index: -10;
      }
    }
  }

  .explore,
  .connect,
  .follow {
    margin-bottom: 48px;
  }

  .footer {
    &__icons {
      padding-top: 2px;

      a {
        margin-right: 10px;
      }
    }
  }

  .nav-bg {
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0;
    z-index: 999;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: all 0.2s;
  }

  .nav-bar {
    &__checkbox {
      display: none;
    }

    &__close--mobile,
    &__open--mobile {
      display: block;
      cursor: pointer;
    }

    &__open--mobile {
      position: relative;
      top: auto;
    }

    &__logo {
      height: 81px;
      width: 30%;
    }

    &__hamburger {
      &--bar2 {
        transition: all 0s !important;
      }

      &--dark-bar,
      &--light-bar {
        content: "";
        height: 2px;
        width: 24px;
        transition: all ease 0.4s;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &--dark-bar {
        background-color: var(--off-black);
      }

      &--light-bar {
        background-color: white;
      }
    }

    &__links--mobile {
      pointer-events: none;
      height: 100%;
      min-height: 100vh;
      overflow: auto;
      width: 0;
      position: fixed;
      top: 0;
      right: -100vw;
      z-index: 750000;
      opacity: 0;
      background: var(--off-black);
      padding: 60px 50px;
      transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
      scrollbar-width: none;

      /* width */
      ::-webkit-scrollbar {
        display: none;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        display: none;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        display: none;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        display: none;
      }
    }

    &__checkbox:checked ~ .nav-bar__links--mobile {
      width: 500px;
      max-width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      opacity: 1;
      pointer-events: auto;
      right: 0;
    }

    &__checkbox:checked ~ .nav-bg {
      opacity: 0.7;
      pointer-events: auto;
    }

    &__checkbox:checked + .nav-bar__open--mobile .nav-bar__hamburger--bar2 {
      /* background: var(--off-black); */
      opacity: 0;
      z-index: 1000000;
    }

    &__checkbox:checked + .nav-bar__open--mobile .nav-bar__hamburger--bar1 {
      z-index: 1000000;
      transform: rotate(135deg);
      position: relative;
      top: 8px;
      background: white;
    }

    &__checkbox:checked + .nav-bar__open--mobile .nav-bar__hamburger--bar3 {
      z-index: 1000000;

      transform: rotate(-135deg);
      position: relative;
      top: -6px;
      background: white;
    }

    &__checkbox:checked + .nav-bar__open--mobile {
      content: "";
      z-index: 900000;
      height: 30px;
      width: 34px;
    }
  }

  @media only screen and (max-width: 1145px) and (pointer: coarse) {
    padding: 0 12px;
  }

  /* @media only screen and (max-width: 600px) and (pointer: coarse) {
    .nav-bar {
      &__checkbox:checked ~ .nav-bar__links--mobile {
        width: 100vw;
      }

      &__links--mobile {
        padding: 60px 30px;
      }
    }
  } */
`;

export default NavStyles;
