import React from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import GlobalStyles from "../../styles/GlobalStyles";
import Typography from "../../styles/Typography";

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav color="light" />
      <main>{children}</main>
      <Analytics />
      <SpeedInsights />
      <Footer />
    </>
  );
};

export default Layout;
