import { createGlobalStyle } from "styled-components";

import apercuRegWoff from "../assets/fonts/apercu-regular.woff";
import apercuBoldWoff from "../assets/fonts/apercu-bold.woff";
import moderatRegWoff from "../assets/fonts/Moderat-Regular.woff";
import moderatBoldWoff from "../assets/fonts/Moderat-Bold.woff";

const Typography = createGlobalStyle`
@font-face {
  font-family: "apercu-regular";
  src: url(${apercuRegWoff});
  font-weight: normal;
  font-display: fallback;
}

@font-face {
  font-family: "apercu-bold";
  src: url(${apercuBoldWoff});
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: "moderat-regular";
  src: url(${moderatRegWoff});
  font-weight: normal;
  font-display: fallback;
}

@font-face {
  font-family: "moderat-bold";
  src: url(${moderatBoldWoff});
  font-weight: bold;
  font-display: fallback;
}

:root {
  --header-font: "apercu-regular", Arial, sans-serif;
  --header-font-bold: "apercu-bold", Arial,sans-serif;
  --body-font: "moderat-regular", Arial,sans-serif;
  --body-font-bold: "moderat-bold", Arial,sans-serif;
}

html {
  font-family: var(--body-font);
  font-weight: normal !important;
  font-size: 18px;
  line-height: 26px;
  color: var(--off-black);
  overflow-x: hidden !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal !important;
}

.black {
  color: black !important;
}

.sixteen-px {
  font-size: 16px !important;
}

.bold-body {
  font-family: var(--body-font-bold) !important;
}

.text-link {
  color: var(--cornflower);
}

.uppercase {
  text-transform: uppercase;
}


.white-text {
  color: white !important;
}

.medium-gray-text {
  color: var(--medium-gray) !important;
}

.pull-quote {
  font-family: var(--header-font);
  font-size: 24px;
  line-height: 32px;
  margin: 40px auto;
  width: 350px;
  max-width: 70%;
  text-align: center;
}

.bold-text {
  font-family: var(--body-font-bold)
}
`;

export default Typography;
