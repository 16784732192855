import emailjs from "@emailjs/browser";

export function emailSignup(e, setConfirmation) {
  setConfirmation(true);
  emailjs
    .sendForm(
      process.env.GATSBY_EMAIL_JS_SERVICE_ID,
      "to_go_suggestion",
      e.target,
      process.env.GATSBY_EMAIL_JS_PUBLIC_KEY
    )
    .then(
      (result) => {
        // LogRocket.log(result.text);
      },
      (error) => {
        // LogRocket.log(error.text);
      }
    );
}
