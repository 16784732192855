import * as React from "react";

const Logo = ({ color }) => {
  if (color === "light") {
    color = "#FFFFFF";
  } else if (color === "dark") {
    color = "#231f20";
  }

  return (
    <div className="logo-container">
      <svg
        width="108"
        height="47"
        viewBox="0 0 108 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.0376 14.1851H30.6951C30.4032 14.1851 30.2281 14.4185 30.2281 14.652V20.7224C30.2281 21.0143 29.9946 21.1894 29.7612 21.1894H29.0607C28.7689 21.1894 28.5938 20.9559 28.5938 20.7224V14.652C28.5938 14.3602 28.3603 14.1851 28.1268 14.1851H26.7843C26.4925 14.1851 26.3174 13.9516 26.3174 13.7181V13.1928C26.3174 12.9009 26.5509 12.7258 26.7843 12.7258H32.0376C32.3294 12.7258 32.5045 12.9593 32.5045 13.1928V13.7181C32.5629 14.01 32.3294 14.1851 32.0376 14.1851Z"
          fill={color}
        />
        <path
          d="M40.6181 21.1894H39.9177C39.6842 21.1894 39.5091 21.0726 39.4507 20.8392L39.1005 19.6718C39.0422 19.4383 38.8671 19.3216 38.6336 19.3216H36.1821C35.9486 19.3216 35.7735 19.4383 35.7151 19.6718L35.3649 20.8392C35.3065 21.0726 35.1314 21.1894 34.8979 21.1894H34.1975C33.8473 21.1894 33.6138 20.8392 33.7306 20.5473L36.2404 13.0177C36.2988 12.7842 36.5323 12.6675 36.7074 12.6675H38.1083C38.3417 12.6675 38.5168 12.7842 38.5752 13.0177L41.0851 20.5473C41.2018 20.8392 40.9683 21.1894 40.6181 21.1894ZM38.2834 17.1619L37.5246 14.8855H37.2911L36.5323 17.1619C36.4155 17.5121 36.649 17.804 36.9992 17.804H37.8164C38.1083 17.8623 38.4001 17.5121 38.2834 17.1619Z"
          fill={color}
        />
        <path
          d="M49.6068 18.3293C49.8402 19.8469 48.6145 21.1894 47.0385 21.1894H43.8282C43.7115 21.1894 43.5947 21.0726 43.5947 20.9559V13.0177C43.5947 12.8426 43.7115 12.7258 43.8866 12.7258H46.5132C47.7389 12.7258 49.1982 13.4263 49.1982 14.9439C49.1982 15.6443 48.9063 16.2864 48.2059 16.6366V16.7533C48.9647 16.87 49.49 17.4537 49.6068 18.3293ZM45.1707 15.9361C45.1707 15.9945 45.2291 16.1112 45.3458 16.1112H46.3381C47.4471 16.1112 47.6222 15.5859 47.6222 15.1773C47.6222 14.5936 47.2136 14.1851 46.3381 14.1851H45.1707V15.9361ZM48.0308 18.6211C48.0308 18.0958 47.6806 17.4537 46.5716 17.4537H45.2291V19.6718H46.4548C47.5638 19.7885 48.0308 19.2632 48.0308 18.6211Z"
          fill={color}
        />
        <path
          d="M58.2459 20.022V20.8392C58.2459 21.0726 58.0708 21.2478 57.8373 21.2478H52.7592C52.5257 21.2478 52.3506 21.0726 52.3506 20.8392V13.0761C52.3506 12.8426 52.5257 12.6675 52.7592 12.6675H53.6931C53.9266 12.6675 54.1017 12.8426 54.1017 13.0761V19.2048C54.1017 19.4383 54.2768 19.6134 54.5102 19.6134H57.8957C58.0708 19.6134 58.2459 19.7885 58.2459 20.022Z"
          fill={color}
        />
        <path
          d="M66.7082 20.1387V20.7808C66.7082 21.0143 66.5331 21.2478 66.2413 21.2478H61.3966C61.1632 21.2478 60.9297 21.0726 60.9297 20.7808V13.1344C60.9297 12.901 61.1048 12.6675 61.3966 12.6675H66.1245C66.358 12.6675 66.5915 12.8426 66.5915 13.1344V13.7765C66.5915 14.01 66.4164 14.2434 66.1245 14.2434H63.031C62.7975 14.2434 62.564 14.4186 62.564 14.7104V15.7027C62.564 15.9362 62.7391 16.1696 63.031 16.1696H65.7743C66.0078 16.1696 66.2413 16.3447 66.2413 16.6366V17.2203C66.2413 17.4538 66.0662 17.6872 65.7743 17.6872H63.031C62.7975 17.6872 62.564 17.8623 62.564 18.1542V19.2632C62.564 19.4967 62.7391 19.7302 63.031 19.7302H66.2413C66.4748 19.6718 66.7082 19.9053 66.7082 20.1387Z"
          fill={color}
        />
        <path
          d="M76.8653 21.2478C75.231 21.2478 74.0052 20.3139 73.7718 18.9714C73.7134 18.6795 73.9469 18.4461 74.1803 18.4461H74.9392C75.1143 18.4461 75.2894 18.5628 75.3477 18.7379C75.5812 19.5551 76.34 19.7302 76.8653 19.7302C77.2739 19.7302 78.0911 19.5551 78.0911 18.7963C78.0911 18.0959 77.3907 17.804 76.6902 17.5122C75.5228 17.0452 73.9469 16.5199 73.9469 14.8855C73.9469 13.4263 75.1726 12.5508 76.7486 12.5508C78.2078 12.5508 79.1417 13.368 79.4336 14.2435C79.5503 14.5353 79.3168 14.8272 79.025 14.8272H78.2662C78.0911 14.8272 77.9744 14.7688 77.8576 14.5937C77.6825 14.2435 77.2155 14.0684 76.7486 14.0684C76.1649 14.0684 75.6979 14.3602 75.6979 14.8855C75.6979 15.4109 76.1649 15.6443 76.9821 15.9946C77.8576 16.4031 79.7838 16.8701 79.7838 18.7963C79.7254 20.4306 78.4413 21.2478 76.8653 21.2478Z"
          fill={color}
        />
        <path
          d="M88.7718 21.1894H88.013C87.8379 21.1894 87.6628 21.0726 87.6044 20.8975L87.1958 19.6718C87.1374 19.4967 86.9623 19.3799 86.7872 19.3799H84.2774C84.1022 19.3799 83.9271 19.4967 83.8688 19.6718L83.4602 20.8975C83.4018 21.0726 83.2267 21.1894 83.0516 21.1894H82.2928C82.001 21.1894 81.7675 20.8975 81.8842 20.6057L84.4525 13.0177C84.5108 12.8426 84.6859 12.7258 84.861 12.7258H86.3203C86.4954 12.7258 86.6705 12.8426 86.7289 13.0177L89.2971 20.6057C89.2971 20.8975 89.122 21.1894 88.7718 21.1894ZM86.3786 17.2203L85.6198 14.8855H85.3864L84.6276 17.2203C84.5108 17.5121 84.7443 17.804 85.0362 17.804H85.9701C86.2619 17.8623 86.4954 17.5121 86.3786 17.2203Z"
          fill={color}
        />
        <path
          d="M98.5782 16.9285V20.8392C98.5782 21.0143 98.4031 21.1894 98.228 21.1894H97.7611C97.586 21.1894 97.4692 21.0727 97.4108 20.8976L97.2941 20.4306H97.119C96.7104 20.8976 96.0684 21.1894 94.9593 21.1894C92.6829 21.1894 90.9902 19.38 90.9902 16.8701C90.9902 14.3602 92.6246 12.5508 95.0177 12.5508C96.9439 12.5508 97.8778 13.7765 98.2864 14.477C98.4031 14.7104 98.228 15.0023 97.9362 15.0023H96.9439C96.8272 15.0023 96.7104 14.9439 96.652 14.8272C96.4769 14.5353 96.01 14.0684 95.0761 14.0684C93.6752 14.0684 92.6829 15.2358 92.6829 16.8701C92.6829 18.5044 93.6168 19.6135 95.1344 19.6135C96.01 19.6135 96.652 19.2632 97.0023 18.7379C97.0606 18.6795 97.0606 18.6212 97.0606 18.5628V18.271C97.0606 18.0959 96.8855 17.9207 96.7104 17.9207H95.6014C95.4263 17.9207 95.2512 17.7456 95.2512 17.5705V16.8701C95.2512 16.695 95.4263 16.5199 95.6014 16.5199H98.2864C98.4615 16.5783 98.5782 16.695 98.5782 16.9285Z"
          fill={color}
        />
        <path
          d="M107.334 20.0804V20.8392C107.334 21.0726 107.159 21.1894 106.983 21.1894H102.022C101.789 21.1894 101.672 21.0143 101.672 20.8392V13.076C101.672 12.8426 101.847 12.7258 102.022 12.7258H106.867C107.1 12.7258 107.217 12.9009 107.217 13.076V13.8348C107.217 14.0683 107.042 14.1851 106.867 14.1851H103.598C103.365 14.1851 103.248 14.3602 103.248 14.5353V15.7027C103.248 15.9361 103.423 16.0529 103.598 16.0529H106.517C106.75 16.0529 106.867 16.228 106.867 16.4031V17.1619C106.867 17.3954 106.692 17.5121 106.517 17.5121H103.598C103.365 17.5121 103.248 17.6872 103.248 17.8623V19.1464C103.248 19.3799 103.423 19.4967 103.598 19.4967H106.983C107.159 19.6718 107.334 19.8469 107.334 20.0804Z"
          fill={color}
        />
        <path
          d="M17.6211 25.1584H16.8039C16.5704 25.1584 16.3953 25.3919 16.4537 25.6254C17.0958 28.3104 19.197 30.4117 21.882 31.1121C22.1155 31.1705 22.349 30.9954 22.349 30.7619V29.8864C22.349 29.7112 22.2322 29.5945 22.0571 29.5361C20.0726 28.9524 18.555 27.4348 17.9713 25.4503C17.9129 25.2752 17.7378 25.1584 17.6211 25.1584Z"
          fill={color}
        />
        <path
          d="M22.2904 17.6873V16.8117C22.2904 16.5782 22.0569 16.4031 21.8234 16.4615C19.1385 17.1619 17.0372 19.2632 16.3951 21.9482C16.3367 22.1817 16.5118 22.4152 16.7453 22.4152H17.5625C17.7376 22.4152 17.8543 22.2984 17.9127 22.1233C18.4964 20.1388 20.014 18.6212 21.9985 18.0375C22.1737 17.9791 22.2904 17.8624 22.2904 17.6873Z"
          fill={color}
        />
        <path
          d="M30.5783 25.1584H29.7611C29.586 25.1584 29.4693 25.2752 29.4109 25.4503C28.8272 27.4348 27.3096 28.9524 25.325 29.5361C25.1499 29.5945 25.0332 29.7112 25.0332 29.8864V30.7619C25.0332 30.9954 25.2667 31.1705 25.5002 31.1121C28.1851 30.47 30.2864 28.3104 30.9285 25.6254C30.9869 25.3919 30.8118 25.1584 30.5783 25.1584Z"
          fill={color}
        />
        <path
          d="M22.2893 13.6599V12.8427C22.2893 12.6093 22.0558 12.4341 21.8224 12.4925C16.9194 13.2513 13.067 17.1037 12.3082 22.0067C12.2498 22.2402 12.4249 22.4736 12.6584 22.4736H13.4756C13.6507 22.4736 13.8258 22.3569 13.8258 22.1818C14.5262 18.0376 17.7949 14.8273 21.8807 14.1269C22.1726 14.0101 22.2893 13.835 22.2893 13.6599Z"
          fill={color}
        />
        <path
          d="M13.5342 25.1584H12.717C12.4835 25.1584 12.3084 25.3919 12.3668 25.6254C13.1256 30.5284 16.9779 34.3808 21.881 35.1396C22.1144 35.198 22.3479 35.0228 22.3479 34.7894V33.9722C22.3479 33.7971 22.2312 33.622 22.0561 33.622C17.9119 32.9216 14.7015 29.6529 14.0011 25.567C13.8844 25.2752 13.7093 25.1584 13.5342 25.1584Z"
          fill={color}
        />
        <path
          d="M34.6058 25.1584H33.7886C33.6135 25.1584 33.4384 25.2752 33.4384 25.4503C32.7379 29.5945 29.4693 32.8048 25.325 33.5052C25.1499 33.5636 25.0332 33.6804 25.0332 33.8555V34.731C25.0332 34.9645 25.2667 35.1396 25.5002 35.0812C30.4032 34.3224 34.2555 30.47 35.0143 25.567C35.0143 25.3336 34.8392 25.1584 34.6058 25.1584Z"
          fill={color}
        />
        <path
          d="M38.5746 25.1584H37.7575C37.5824 25.1584 37.4073 25.2752 37.4073 25.5087C36.6485 31.8125 31.6287 36.8323 25.3248 37.5911C25.1497 37.5911 24.9746 37.7662 24.9746 37.9413V38.7585C24.9746 38.992 25.1497 39.1671 25.3832 39.1087C32.4459 38.2915 38.1077 32.6297 38.9249 25.567C38.9832 25.3336 38.8081 25.1584 38.5746 25.1584Z"
          fill={color}
        />
        <path
          d="M22.2901 9.63231V8.81514C22.2901 8.58166 22.115 8.40656 21.8815 8.46493C14.8188 9.2821 9.15701 14.9439 8.33984 22.0066C8.33984 22.2401 8.51495 22.4152 8.69006 22.4152H9.50723C9.68234 22.4152 9.85744 22.2984 9.85744 22.065C10.6162 15.7611 15.636 10.7997 21.9399 9.98253C22.1733 9.98253 22.2901 9.86579 22.2901 9.63231Z"
          fill={color}
        />
        <path
          d="M9.56542 25.1584H8.74825C8.51477 25.1584 8.33966 25.3336 8.39803 25.567C9.2152 32.6297 14.8186 38.2915 21.9397 39.1087C22.1732 39.1087 22.3483 38.9336 22.3483 38.7585V37.9413C22.3483 37.7662 22.2315 37.5911 21.9981 37.5911C15.6942 36.8323 10.7328 31.8125 9.91563 25.5087C9.91563 25.2752 9.74052 25.1584 9.56542 25.1584Z"
          fill={color}
        />
        <path
          d="M5.59569 25.1584H4.77852C4.54504 25.1584 4.36994 25.3336 4.4283 25.567C5.24547 34.8477 12.6584 42.2606 21.9391 43.0778C22.1725 43.0778 22.3477 42.9027 22.3477 42.7276V41.9104C22.3477 41.7353 22.1725 41.5602 21.9974 41.5602C13.5339 40.743 6.76307 33.9722 6.00427 25.5087C5.9459 25.2752 5.7708 25.1584 5.59569 25.1584Z"
          fill={color}
        />
        <path
          d="M22.2904 5.66307V4.8459C22.2904 4.61243 22.1153 4.43732 21.8819 4.49569C12.6012 5.31286 5.18826 12.7257 4.37109 22.0065C4.37109 22.2399 4.5462 22.415 4.72131 22.415H5.53848C5.71359 22.415 5.88869 22.2399 5.88869 22.0648C6.76423 13.6013 13.4767 6.83046 21.9402 6.07166C22.1737 6.01329 22.2904 5.89655 22.2904 5.66307Z"
          fill={color}
        />
        <path
          d="M42.6023 25.1584H41.7852C41.6101 25.1584 41.435 25.3336 41.435 25.5087C40.6178 33.9722 33.847 40.743 25.3834 41.5602C25.2083 41.5602 25.0332 41.7353 25.0332 41.9104V42.7276C25.0332 42.9611 25.2083 43.1362 25.4418 43.0778C34.7225 42.2606 42.1354 34.8477 42.9526 25.567C42.9526 25.3336 42.7774 25.1584 42.6023 25.1584Z"
          fill={color}
        />
        <path
          d="M22.2898 1.69408V0.876909C22.2898 0.643432 22.1147 0.468324 21.8812 0.526694C10.4409 1.40223 1.27691 10.5662 0.401367 22.0066C0.401367 22.24 0.576475 22.4151 0.751582 22.4151H1.56875C1.74386 22.4151 1.91897 22.24 1.91897 22.0649C2.73614 11.4417 11.258 2.91983 21.8812 2.10266C22.1731 2.04429 22.2898 1.92755 22.2898 1.69408Z"
          fill={color}
        />
        <path
          d="M25.0332 45.8214V46.6386C25.0332 46.8721 25.2083 47.0472 25.4418 46.9888C36.8822 46.1133 46.0461 36.9493 46.9217 25.5089C46.9217 25.2754 46.7465 25.1003 46.5714 25.1003H45.7543C45.5792 25.1003 45.4041 25.2754 45.4041 25.4506C44.5869 36.0738 36.065 44.5957 25.3834 45.4128C25.1499 45.4712 25.0332 45.6463 25.0332 45.8214Z"
          fill={color}
        />
        <path
          d="M1.62596 25.1584H0.808794C0.575317 25.1584 0.400209 25.3336 0.458578 25.567C1.27575 37.0074 10.4397 46.1714 21.8801 46.9885C22.1136 46.9885 22.2887 46.8134 22.2887 46.6383V45.8211C22.2887 45.646 22.1136 45.4709 21.9384 45.4709C11.3153 44.6538 2.79335 36.1319 1.97618 25.5087C1.97618 25.2752 1.80107 25.1584 1.62596 25.1584Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default Logo;
