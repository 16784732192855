import {
  // GET_TAGS,
  SET_LOADING,
  GET_SEARCH_RESULTS_ALL,
  SET_CONSCIOUS_FILTER,
  SET_LIFESTYLE_FILTER,
  SET_RESTAURANT_SEARCH_FILTER,
  SET_ALL_FILTERS,
  SET_RESULTS_PAGE_NUMBER,
  CLEAR_ALL_FILTERS,
  GET_SEARCH_RESULTS_MAP,
  CLEAR_RESTAURANT_LIST,
  // GET_ARTICLE_MAP,
  // SET_MEAL_FILTER,
  // SET_CUISINE_FILTER,
  // SET_PRICE_FILTER,
  // SET_VIBE_FILTER,
  // SET_NEIGHBORHOOD_FILTER,
  // SET_DINING_FILTER,
} from "../actions/types";

const initialState = {
  restaurantList: [],
  restaurantListAll: [],
  searchList: [],
  restaurant: null,
  consciousFilter: [],
  lifestyleFilter: [],
  loading: false,
  searchResultsMap: [],
  restaurantSearchFilter: null,
  features: null,
  currentPage: 1,
  // tags: {
  //   mealTypes: [],
  //   cuisineTypes: [],
  // },
  // articleMap: [],
  // articleFeatures: null,
  // articleRestaurantList: [],
  // cuisineFilter: [],
  // diningFilter: [],
  // mealFilter: [],
  // priceFilter: [],
  // neighborhoodFilter: [],
  // vibeFilter: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
        features: null,
      };
    case SET_RESULTS_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.payload,
      };
    // case GET_TAGS:
    //   return {
    //     ...state,
    //     tags: action.payload,
    //     loading: false,
    //   };
    case SET_ALL_FILTERS:
      return {
        ...state,
        consciousFilter: action.payload.consciousFilter,
        // cuisineFilter: action.payload.cuisineFilter,
        lifestyleFilter: action.payload.lifestyleFilter,
        // diningFilter: action.payload.diningFilter,
        // mealFilter: action.payload.mealFilter,
        // priceFilter: action.payload.priceFilter,
        // vibeFilter: action.payload.vibeFilter,
        // neighborhoodFilter: action.payload.neighborhoodFilter,
        restaurantSearchFilter: action.payload.restaurantSearchFilter,
      };
    case GET_SEARCH_RESULTS_MAP:
      return {
        ...state,
        restaurantList: action.payload,
        loading: false,
      };
    // case GET_ARTICLE_MAP:
    //   return {
    //     ...state,
    //     articleRestaurantList: action.payload,
    //     loading: false,
    //   };
    case GET_SEARCH_RESULTS_ALL:
      return {
        ...state,
        restaurantListAll: action.payload,
        loading: false,
      };
    case CLEAR_ALL_FILTERS:
      return {
        ...state,
        consciousFilter: [],
        lifestyleFilter: [],
        // cuisineFilter: [],
        // diningFilter: [],
        // mealFilter: [],
        // priceFilter: [],
        // vibeFilter: [],
        // neighborhoodFilter: [],
        restaurantSearchFilter: null,
        restaurantList: [],
        currentPage: 1,
      };
    case CLEAR_RESTAURANT_LIST:
      return {
        ...state,
        restaurantList: [],
      };
    case SET_CONSCIOUS_FILTER:
      return {
        ...state,
        consciousFilter: action.payload,
        restaurantSearchFilter: null,
        currentPage: 1,
      };
    case SET_LIFESTYLE_FILTER:
      return {
        ...state,
        lifestyleFilter: action.payload,
        restaurantSearchFilter: null,
        currentPage: 1,
      };
    case SET_RESTAURANT_SEARCH_FILTER:
      return {
        ...state,
        lifestyleFilter: [],
        consciousFilter: [],
        // mealFilter: [],
        // cuisineFilter: [],
        // diningFilter: [],
        // vibeFilter: [],
        // priceFilter: [],
        // neighborhoodFilter: [],
        restaurantSearchFilter: action.payload,
        currentPage: 1,
      };
    // case SET_MEAL_FILTER:
    //   return {
    //     ...state,
    //     mealFilter: action.payload,
    //     restaurantSearchFilter: null,
    //   };
    // case SET_CUISINE_FILTER:
    //   return {
    //     ...state,
    //     cuisineFilter: action.payload,
    //     restaurantSearchFilter: null,
    //   };
    // case SET_DINING_FILTER:
    //   return {
    //     ...state,
    //     diningFilter: action.payload,
    //     restaurantSearchFilter: null,
    //   };
    // case SET_PRICE_FILTER:
    //   return {
    //     ...state,
    //     priceFilter: action.payload,
    //     restaurantSearchFilter: null,
    //   };
    // case SET_VIBE_FILTER:
    //   return {
    //     ...state,
    //     vibeFilter: action.payload,
    //     restaurantSearchFilter: null,
    //   };
    // case SET_NEIGHBORHOOD_FILTER:
    //   return {
    //     ...state,
    //     neighborhoodFilter: action.payload,
    //     restaurantSearchFilter: null,
    //   };
    default:
      return state;
  }
}
